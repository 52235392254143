import React, { useState } from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Tooltip, Grid, Avatar, Input, TextField, FormControlLabel, RadioGroup, Checkbox, Radio, Typography, IconButton, TextareaAutosize } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AddAPhoto } from '@mui/icons-material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import Avatarimage from "../../../assets/images/Avatar.png"
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoComplete from "./Autocomplete";
import { AiOutlineInfoCircle } from 'react-icons/ai';






;

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}


BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});


export default function AddTeacher() {
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }

    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }


    //UseState's:
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()



    const [user, setUser] = React.useState({ firstname: '', username: '', email: '',calPasskey:"", gender: 'Male', phone: '', instagram: '', facebook: '', twitter: '', youtube: '', pinterest: '', tiktok: '', Imageprew: "", Imageraw: "", specialization: [''], description: "", plan: '', userid: '' });
    const [error, setError] = React.useState({ firstname: "", username: "", email: "", subscription: "", location: '' })
    const [isToggled] = React.useState(false)
    const [plans, setPlans] = React.useState([])
    const [cityName, setCityName] = React.useState('')
    const [selectedPlan, setSelectedPlan] = useState('')
    const [isChecked, setChecked] = React.useState(false)
    const [isApproveChecked, setIsApproveChecked] = React.useState(false)
    const [isLegend, setIsLegend] = React.useState(false)
    const [isPrimary, setPrimary] = React.useState(false)
    const [lat, setLat] = React.useState('')
    const [lng, setLng] = React.useState('')
    const [truebutton, setTruebutton] = React.useState(false)
    const [availForMeeting, setAvailForMeeting] = React.useState(false )
    

    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };


    const handleChange = (e, index) => {
        const { name, value } = e.target;
        let isValid = true;
        let errorMessage = '';


        if (name === 'username') {
            const trimmedValue = value.trim(); // Trim leading and trailing spaces

            // Check if the value is only special characters
            if (/^[^a-zA-Z0-9]+$/.test(trimmedValue)) {
                isValid = false;
                errorMessage = "Name should not contain only special characters";
            }
            if (/^\d+$/.test(trimmedValue)) {
                isValid = false;
                errorMessage = "Name should not be only numbers";
            }
            if (/^[^a-zA-Z0-9]/.test(trimmedValue) && /[a-zA-Z]/.test(trimmedValue)) {
                isValid = false;
                errorMessage =
                    "Name should not contain both alphabets and special characters";
            }

            if (/^[a-zA-Z]+$/.test(trimmedValue)) {
                isValid = true;
                errorMessage = "";
            }

            if (value.includes(" ")) {
                isValid = false;
                errorMessage =
                    "This feild not contain space";
            }

        }
        if (name === 'firstname') {

            if (/^[^a-zA-Z0-9]+$/.test(value)) {
                isValid = false;
                errorMessage = "Name should not contain  only  special characters";
            }
            if (/.*[0-9].*/.test(value)) {
                isValid = false;
                errorMessage = "Name should not contain Number";
            }
        }
        if (name === "phone") {
            if (/^[0-9]{11}$/.test(value)) {
                isValid = false;
                errorMessage = "Phone number should contain only 10 digits";
            }
        }
        // Validate Email
        if (name === 'email') {
            if (!value || /^\d+$/.test(value) || /^[^a-zA-Z0-9]+$/.test(value)) {
                isValid = false;
                errorMessage = "Please enter a valid Email";
            } else {
                const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
                if (!emailPattern.test(value)) {
                    isValid = false;
                    errorMessage = "Please enter a valid Email";
                }
            }
        }

        // Validate Specialization
        if (name === 'specialization') {
            if (!value || /^\d+$/.test(value) || /^[^a-zA-Z0-9]+$/.test(value)) {
                isValid = false;
                errorMessage = "Specialization should not contain only numbers or special characters";
            }
        }



        // Update state based on validation result
        if (name === 'specialization') {
            const updatedSpecializations = [...user.specialization];
            updatedSpecializations[index] = value;
            setUser({ ...user, specialization: updatedSpecializations });
        } else {
            setUser({ ...user, [name]: value });
        }

        // Update error state
        setError({ ...error, [name]: errorMessage });
        return isValid;

    };

    const handleAddSpecialization = () => {
        setUser({ ...user, specialization: [...user.specialization, ''] });
    };

    const handleRemoveSpecialization = (index) => {
        const updatedSpecializations = [...user.specialization];
        updatedSpecializations.splice(index, 1);
        setUser({ ...user, specialization: updatedSpecializations });
    };

    const handleIsAvailForMeeting = () => {
        setAvailForMeeting(!availForMeeting)
    }



    const handleaddphoto = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
            setError({ image: 'Image should be less than 1 MB' });
            setUser({
                ...user,
                Imageprew: '',
                Imageraw: null,
            });
        } else {
            setUser({
                ...user,
                Imageprew: URL.createObjectURL(file),
                Imageraw: file,
            });
            setError({ image: '' });
        }
    };
    <label htmlFor="icon-button-file">
        <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleaddphoto} />
        <IconButton color="#f3714d" aria-label="upload picture" component="span">
            {user.Imageprew ? <Avatar src={user.Imageprew} /> : <Avatar src={Avatarimage} />}
            <AddAPhoto />
        </IconButton>
    </label>


    const handleChecked = () => {
        setChecked(!isChecked)
    }

    const handleApproveChecked = () => {
        setIsApproveChecked(!isApproveChecked)
    }

    const handleIsLegend = () => {
        setIsLegend(!isLegend)
    }

    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }



    const validation = () => {
        let isValid = true;
        const errors = {};

        if (!selectedPlan) {
            errors.subscription = "This field is required.";
            isValid = false;
        }

        const trimmedValue = user.username.trim();

        if (!user.username || trimmedValue === '') {
            errors.username = "This field is required.";
            isValid = false;
        }

        if (!user.firstname) {
            errors.firstname = "This field is required. ";
            isValid = false;
        }



        if (!user.phone) {
            errors.phone = "This field is required.";
            isValid = false;
        }

        if (!cityName) {
            errors.location = "This field is required.";
            isValid = false;
        }

        if(availForMeeting){
            if(!user.calPasskey){
                errors.calPasskey = "This field is required";
                isValid = false;
            }
        }

        if (!user.email) {
            errors.email = "This field is required.";
            isValid = false;
        } else {
            const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
            if (!emailPattern.test(user.email)) {
                errors.email = "Please enter a valid Email";
                isValid = false;
            }
        }

        setError(errors);
        return isValid;
    };


    const subscriptionDetails = (q, cid, subscribeId) => {
        let body = {
            planId: selectedPlan.stripePlanId,
            userId: q,
            customerId: cid,
            subscriptionId: subscribeId

        };

        axios.post(process.env.REACT_APP_BASE_URL + `subscription/create-session`, body, headerlocal)
            .then((res) => {

            })
            .catch((err) => {

                console.log(err, "session error");
            });
    };



    //Add Teacher:
    const addUserDetails = (e, details) => {
        // navigate(`/teachers/details/podcast/${'6673e9e1baded3a24bf857c1'}`);
        const validator = validation();
        if (validator === true) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("usertype", 'teacher')
            formData.append("image", user.Imageraw ? user.Imageraw : "");
            formData.append("name", user.firstname);
            formData.append("username", user.username);
            formData.append("email", user.email);
            formData.append("bundleApproved", isApproveChecked);
            formData.append("isLegend", isLegend);

            formData.append("SetVerifyTeacher", isToggled);
            // formData.append("specialization", user.specialization);
            const transformedSpecializations = user.specialization.flatMap(spec => spec.split(',').map(s => s.trim())).filter(Boolean);
            transformedSpecializations.forEach((spec, index) => {
                formData.append(`specialization[${index}]`, spec);
            });
            formData.append("jobApproveByAdmin", false)
            formData.append("address", cityName);
            formData.append("latitude", lat);
            formData.append("longitude", lng);
            formData.append("description", user.description ? user.description : '');
            formData.append("contact_number", user.phone);
            formData.append("gender", user.gender ? user.gender : '');
            formData.append("role_id", 2);
            formData.append("role", "Teacher");
            formData.append("merit", isChecked)
            formData.append("senior_teacher", isPrimary)
            formData.append("planId", selectedPlan._id)
            formData.append("AvailForMeeting", availForMeeting)
            formData.append("calPasskey", availForMeeting ? user.calPasskey : "" )
            formData.append("instagram", user.instagram ? user.instagram : '')
            formData.append("facebook", user.facebook ? user.facebook : '')
            formData.append("twitter", user.twitter ? user.twitter : '')
            formData.append("youtube", user.youtube ? user.youtube : '')
            formData.append("pinterest", user.pinterest ? user.pinterest : '')
            formData.append("tiktok", user.tiktok ? user.tiktok : '')
            formData.append("addTeacherFrom", 'Admin')
            console.log(isLegend);
            

            axios.post(process.env.REACT_APP_BASE_URL + `Admin/addTeacher`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false)
                    const { _id } = res.data.AddTeacher;
                    let cid = res.data.AddTeacher.stripeCustomerId
                    subscriptionDetails(_id, cid, selectedPlan._id);
                    if (details === 'addWithNavigate') {
                        setUser({ firstname: '', email: '', SetVerifyTeacher: "", gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: "", description: "" });
                        setSelectedPlan('');
                        navigate(`/teachers/details/events/${"Teacher"}/${_id}`);
                    } else if (details === 'add') {
                        setUser({ firstname: '', email: '', SetVerifyTeacher: "", gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: "", description: "" });
                        setSelectedPlan('');
                        setCityName('')
                        enqueueSnackbar("Teacher added successfully", { variant: 'success' });
                        navigate('/teachers')
                    }
                })
                .catch((err) => {
                    setTruebutton(false)
                    console.log(err.response, "------");
                    enqueueSnackbar(err.response.data.error ? err.response.data.error : err.response.data ? err.response.data.message : "Error occurred", { variant: 'error' });
                });
        }
    };


    React.useEffect(() => {
        getPlans()
    }, [])

    const getPlans = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Subscription/getSubscriptionPlansYogaTeacher`, { headers: headerlocal }).then((res) => {
            setPlans(res.data.getSubscriptiondata)
        }).catch((error) => {
            console.log(error, "Error ");
        })
    }

    return (
        <Box className='Addcompany'>
            <MiniDrawer menuOrder={2} submenuOrder={5} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add Teacher</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item sm={12} md={12} lg={12} className='mb-20'>
                                        <Box className='personal-info-tab'>
                                            <Typography component='div' className='label' >
                                                <center>Profile Photo<Typography component='span' className='star'></Typography></center>
                                            </Typography>
                                            <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleaddphoto} />
                                                    <IconButton color="#f3714d" aria-label="upload picture" component="span">
                                                        {user.Imageprew ? <Avatar src={user.Imageprew}>
                                                        </Avatar> : <Avatar src={Avatarimage} />
                                                        }
                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed *.jpeg, *.jpg, *.png<br />max size of 1 MB
                                            </Typography>
                                            {/* <span style={{ color: "red", fontSize: 12 }} className='text-center'>{error.image}</span> */}

                                        </Box>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={12}>
                                        <FormControl fullWidth >
                                            <Typography component='div' className='label'>Select Subscription <Typography component='span' className='star'>*</Typography></Typography>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedPlan ? selectedPlan : 'sel'}
                                                placeholder={selectedPlan && selectedPlan}
                                                className="select"
                                                onChange={(e) => { setSelectedPlan(e.target.value); setError('') }}
                                            >
                                                <MenuItem disabled value={'sel'}>Select Subscription Plan</MenuItem>
                                                {plans && plans?.length > 0 ? plans.map((data) => (

                                                    <MenuItem value={data}>{data.name}</MenuItem>
                                                )) : <MenuItem disabled>{"No Plan's found"}</MenuItem>}
                                            </Select>
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.subscription}</span>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='Name'
                                                value={user.firstname}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Username: <Typography component='span' className='star'>*</Typography>
                                                <Tooltip title="This name will be used to create a unique URL for teacher">
                                                    <IconButton aria-label="information">
                                                        <AiOutlineInfoCircle size={20} />
                                                    </IconButton>
                                                </Tooltip></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='username'
                                                className='textfield'
                                                placeholder='Username'
                                                value={user.username}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </FormControl>
                                        {error.username && <div className="error-message" style={{ color: "red", fontSize: 12 }}>{error.username}</div>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={user.email}
                                                placeholder='Email'
                                                onChange={(e) => { handleChange(e); setError('') }}

                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid>

                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className='mb-10 flex-center' >
                                            <Typography component='div' variant='body1' className='fz-14 label mb-0 '>Teacher Approved?   </Typography>
                                            <FormControlLabel className='m-0' control={<Switch  checked={isToggled} size='small' onClick={handleToggle} sx={{ m: 1 }} />} />
                                        </Box>
                                    </Grid> */}
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={isPrimary}
                                                    onChange={handleTogglePrimary}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Senior Teacher? </Typography>}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Specialization: <Typography component='span' ></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='specialization'
                                                className='textfield'
                                                placeholder='Specialization'
                                                value={user.specialization}
                                                onChange={handleChange}

                                            />
                                        </FormControl>
                                    </Grid> */}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>
                                                Specialization:
                                            </Typography>
                                            {user.specialization.map((value, index) => (
                                                <Grid container spacing={1} alignItems="center" key={index}>
                                                    <Grid item xs={index > 0 ? 10 : 12}> {/* Adjust width for cancel icon */}
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            className='textfield'
                                                            name='specialization'
                                                            placeholder={`Specialization`}
                                                            value={value}
                                                            onChange={(e) => handleChange(e, index)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {index > 0 && (
                                                        <Grid item xs={2}>
                                                            <IconButton onClick={() => handleRemoveSpecialization(index)}>
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            ))}
                                            <IconButton onClick={handleAddSpecialization}>
                                                <AddIcon />
                                            </IconButton>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={handleChecked}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Would you like to add this teacher to the Teachers of Great Merit?</Typography>}
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={isApproveChecked}
                                                    onChange={handleApproveChecked}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Would you like to make this teacher to add bundle offer?</Typography>}
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={isLegend}
                                                    onChange={handleIsLegend}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Would you like to Make this teacher as Yoga legends?</Typography>}
                                        />
                                    </Grid>

                                     <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                            <FormControlLabel className="checkbox_list"
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={availForMeeting}
                                                                                        onChange={handleIsAvailForMeeting}
                                                                                        name='checkbox-value'
                                                                                        color='primary'
                                                                                    />
                                                                                }
                                                                                label={<Typography component='div' className='label mb-0'>Would you like to Make this teacher Avail For Meeting?</Typography>}
                                                                            />
                                                                        </Grid>
                                    
                                                                        {
                                                                            availForMeeting && <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                            <FormControl variant="standard" fullWidth>
                                                                                <Typography component='div' className='label'>Cal Pass Key<Typography component='span' className='star'>*</Typography></Typography>
                                                                                <TextField
                                                                                    id="filled-size-small"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    name='calPasskey'
                                                                                    className='textfield'
                                                                                    placeholder='PassKey'
                                                                                    value={user.calPasskey}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            </FormControl>
                                                                            <span style={{ color: "red", fontSize: 12 }}>{error.calPasskey}</span>
                                                                        </Grid>
                                                                        }

                                    <Grid item lg={12} className='pt-0'>
                                        <Typography component='div' className='label '>Gender:</Typography>
                                        <Box className=" ">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-customized-radios"
                                                name="gender"
                                                onChange={handleChange}
                                                value={user.gender}
                                            >
                                                <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                className='textfield'
                                                value={user.phone}
                                                placeholder='Phone'
                                                onChange={handleChange}
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 10 }}
                                            />
                                            {error.phone && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.phone}</p>}

                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Address:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Address"
                                                className='textfield textarea'
                                                name='address'
                                                value={user.address}
                                                onChange={handleChange}
                                                minRows={2}
                                            />
                                        </FormControl>
                                    </Grid> */}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Location<Typography component='span' className='star'>*</Typography></Typography>
                                            <AutoComplete cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} setError={setError} className="textfield" />
                                        </FormControl>
                                        {error.location && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.location}</p>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Details"
                                                className='textfield textarea'
                                                name='description'
                                                value={user.description}
                                                onChange={handleChange}
                                                minRows={3}
                                                style={{ resize: 'none' }} />

                                        </FormControl>
                                    </Grid>
                                    {/* Add Social Media Links*/}
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Instagram Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='instagram'
                                                className='textfield'
                                                value={user.instagram}
                                                placeholder='Instagram Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Facebook Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='facebook'
                                                className='textfield'
                                                value={user.facebook}
                                                placeholder='Facebook Link'
                                                onChange={handleChange}
                                            />

                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Twitter Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='twitter'
                                                className='textfield'
                                                value={user.twitter}
                                                placeholder='Twitter Link'
                                                onChange={handleChange}
                                            />

                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Youtube Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='youtube'
                                                className='textfield'
                                                value={user.youtube}
                                                placeholder='Youtube Link'
                                                onChange={handleChange}
                                            />

                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Pinterest Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='pinterest'
                                                className='textfield'
                                                value={user.pinterest}
                                                placeholder='Pinterest Link'
                                                onChange={handleChange}
                                            />

                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Tiktok Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='tiktok'
                                                className='textfield'
                                                value={user.tiktok}
                                                placeholder='Tiktok Link'
                                                onChange={handleChange}
                                            />

                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10' onClick={(e) => addUserDetails(e, 'add')}>Save</Button>
                                        <Button variant="contained" className='addbtn mr-10' onClick={(e) => addUserDetails(e, 'addWithNavigate')}>Save + Continue</Button>
                                    </Box>
                                    : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10'>Please Wait</Button>
                                    </Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}