import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, styled, TextField } from '@mui/material';
import { Add } from '@mui/icons-material';
// import { Editor } from '@tinymce/tinymce-react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';

import {  useParams } from 'react-router-dom';
import Comment_section from './comments';
import CloseIcon from '@mui/icons-material/Close';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw,convertFromRaw } from 'draft-js';

import axios from 'axios';
import { enqueueSnackbar } from "notistack";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));





export default function AccordionTransition() {
    //UseState:
    const { id } = useParams()
    const [chapters, setChapters] = React.useState([])
    const [showContent, setShowContent] = React.useState(false);
    const [addChapter, setAddChapter] = React.useState(false);
    const [addLesson, setAddLesson] = React.useState(false);
 
    const [openview, setOpenview] = React.useState(false);
  
    //Editor:
    const [editorState, setEditorState] = React.useState('');

   
    

    //Chapter:
    const [chaptertitle, setChapterTitle] = React.useState('')
    const [chapterid, setChapterId] = React.useState('')
    const [deleteopen, setDeleteOpen] = React.useState(false)
    const [delchapterid, setDelChapterId] = React.useState('')
    const [showChapter, setShowChapter] = React.useState(false);
    const [editchaptertitle,setEditChapterTitle] = React.useState('');
    const [editchapterdata,setEditChapterdata] = React.useState('')
    //Lesson:
    const [lessonTitle, setLessonTitle] = React.useState('')
    const [editlessontitle,setEditLessontitle] = React.useState('')
    const [deletelessonopen, setDeleteLessonOpen] = React.useState(false)
    const [dellessonid, setDelLessonId] = React.useState('')
    //Enabling:
    const [freelesson,setFreeLesson] = React.useState(false)
    const [enablecomment,setEnableComment] = React.useState(false)
    const [lessonlink,setLessonLink] = React.useState(false)
    const [onlinelink,setOnlineLink] = React.useState('')
    const [freelesson1,setFreeLesson1] = React.useState(false)
    const [enablecomment1,setEnableComment1] = React.useState(false)
    const [lessonlink1,setLessonLink1] = React.useState(false)
    const [onlinelink1,setOnlineLink1] = React.useState('')


    


    //hooks state:
    React.useEffect(() => {
        getChapters()
        // eslint-disable-next-line 
    }, [id])

    const getChapters = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Course1/GetCourse/${id}`);
            setChapters(response.data.course.chapters);
            setOpen(new Array(response.data.length).fill(false));
         
        } catch (error) {
            console.error(error);
        }
    }
    
          
    const [open, setOpen] = React.useState(chapters.map(() => true));


    //Validation:
    const [error, setError] = React.useState({ chaptermsg: '',editchaptermsg:'', editormsg: '', lessonmsg: "" ,newmsg:""})

    const chapterValidation = (data) => {
        if (!chaptertitle) {
            setError({ chaptermsg: 'This field is required' })
            return false
        }
     


            if(!data)
            {
                setError({ editormsg: 'This field is required' })
                return false 
            }
          
           
        return true
    }

  
    


    const EditchapterValidation = (rawContent, data) => {
        if (!editchaptertitle) {
            setError({ editchaptermsg: 'This field is required' })
            return false
        }
       
        
        if (!data) {
            setError({ editormsg: 'This field is required' })
            return false
        }
        return true
    }







    const lessonValidation = (data) => {

        
        
        if (!lessonTitle) {
            setError({ lessonmsg: 'This field is required' })
            return false
        }
        if (!data) {
            setError({ editormsg: 'This field is required' })
            return false
        }
        if(lessonlink1){
            if(!onlinelink1){
                setError({newmsg: 'This filed is required'})
                return false
            }
        }

        return true
    }

    const editLessonValidation = (data) => {


        if (!editlessontitle) {
            setError({ lessonmsg: 'This field is required' })
            return false
        }
        if (!data) {
            setError({ editormsg: 'This field is required' })
            return false
        }
        if(lessonlink){
          
            if(!onlinelink){
                setError({newmsg: 'This filed is required'})
        

                return false
            }
        }

        return true
    }





    


    const handleChange = (index) => (event, isExpanded) => {
        const newOpen = [...open];
        newOpen[index] = isExpanded;
        setOpen(newOpen);
    };

 


    const [activeItemId, setActiveItemId] = React.useState(null);

    const [lessondata,setLessonData] = React.useState('');
    const [chapterdata,setChapterData] = React.useState('')

    const  handleItemClick = (data, item) => {
  
        setFreeLesson(data.freelesson===true ? true : false)
        setLessonLink(data.lessonlink===true ? true : false)
        setOnlineLink(data.onlinelink)
        setEnableComment(data.enableComments===true ? true : false)
        setActiveItemId(data._id);
        setShowContent(true);
        setShowChapter(false)
        setChapterData(data);
        setLessonData(item);

    let raw= data.rawdescription
        try {
            const rawContent = {
                blocks: raw.blocks,
                entityMap: {} 
            };
    
            const contentState = convertFromRaw(rawContent);
            const newEditorState = EditorState.createWithContent(contentState);
         
            setEditorState(newEditorState);
        } catch (error) {
            console.error("Error converting rawdescription to EditorState:", error);
        }
        setEditLessontitle(data.title);
        setAddLesson(false)
        setAddChapter(false)

    };

    //Edit chapter functionality:
    const handleEditChapter = (data) => {
     
        
        setView(false)
        setActiveItemId(data._id);
        setShowContent(false);
        setShowChapter(true);

        

        const raw=  data.rawdescription
        try {
            const rawContent = {
                blocks: raw.blocks,
                entityMap: {} 
            };
    
            const contentState = convertFromRaw(rawContent);
            const newEditorState = EditorState.createWithContent(contentState);
    
            setEditorState(newEditorState);
           
            
        } catch (error) {
            console.error("Error converting rawdescription to EditorState:", error);
        }
        setEditChapterTitle(data.title);
        setEditChapterdata(data)
      
        
        setAddLesson(false);
        setAddChapter(false);
    }

    // View Chap State
    const [view,setView] = React.useState(false)
    //View Chapter
    const handleViewChapter = (data) => {

        

      const raw=  data.rawdescription
       
        
        setActiveItemId(data._id);
        setShowContent(false);
        setShowChapter(true);
        try {
            const rawContent = {
                blocks: raw.blocks,
                entityMap: {} 
            };
    
            const contentState = convertFromRaw(rawContent);
            const newEditorState = EditorState.createWithContent(contentState);
    
            setEditorState(newEditorState);
        } catch (error) {
            console.error("Error converting rawdescription to EditorState:", error);
        }
        setEditChapterTitle(data.title);
        setEditChapterdata(data)
        setAddLesson(false);
        setAddChapter(false);
        setView(true)
    }


    // close the view
    const handleCloseChapterView = ()=>{
        setView(false)
        setAddChapter(false)
        setShowChapter(false)
        setEditChapterTitle('')
        setEditChapterdata('')
        setEditorState('')
        getChapters()
    }





    const AddchapterInfo = () => {
        setAddChapter(true);
        setShowContent(false);
        setAddLesson(false);
        setEditorState('')
        setShowChapter(false)
    }

    const [lessId,setLessId] = React.useState('')
    const handleView = (data) => {
  
        setOpenview(true)
        setLessId(data)
    }

    const handleClickClose = () => {
        setOpenview(false)
    }


    //Text Editor function

    const onEditorStateChange = (newEditorState) => {
        setError({ editormsg: '' })
       
     
       
        setEditorState(newEditorState);
      
    };




    const getEditorContent = () => {
        const contentState = editorState?.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        const plainText = contentState.getPlainText();
        return { rawContent, plainText ,contentState};

    };

 



const [chapLoader,setChapLoader] = React.useState(false)

//AddChapter functionality:
    const AddChapterData = async () => {
        
         const { rawContent, plainText } = editorState!=='' && getEditorContent();
        let valid = chapterValidation(plainText)
        if (valid===true) {
            setChapLoader(true)
           
            let chapterbody = {
                "title": chaptertitle,
                "rawdescription": rawContent,
                "plaindescription": plainText
            }
      
          
            
            
            await axios.post(process.env.REACT_APP_BASE_URL + `Course1/AddChapter/${id}`, chapterbody)
                .then((res) => {
                    enqueueSnackbar('Chapter Added Successfully', { variant: 'success' });
                    setAddChapter(false)
                    setChapterTitle('')
                    setEditorState('')
                    getChapters()
                })
                .catch((error) => {
                    console.log(error, "error in AddChapterData")
                }).finally(()=>{
                    setChapLoader(false)
                })
        }
    }

//cancel adding chapter or close draft for new chapters
const cancleChapter = () => {
    
                setAddChapter(false)
                setChapterTitle('')
                setEditorState('')
                getChapters()
          
    }


//Delete Chapter functionality:
    const deleteChapter = (item) => {
        setDelChapterId(item._id)
        setDeleteOpen(true)
    }

    const DeleteChapterInfo = (data) => {

        
        axios.put(process.env.REACT_APP_BASE_URL + `Course1/DeleteChapter/${id}/${data}`)
            .then((res) => {
                enqueueSnackbar('Chapter Deleted Successfully', { variant: 'success' });
                getChapters()
                setEditorState('')
                setChapterTitle('')
                setLessonTitle('')
                setDelChapterId('')
                setDeleteOpen(false)
                setShowContent(false)


               
            })
            .catch((err) => {
                setDelChapterId('')
                setDeleteOpen(false)
                console.log(err, "error in DeleteChapterInfo");
            })
    }


    const handleDeleteClose = () => {
        setDeleteOpen(false)
        setDelChapterId('')
    }


    const handleUpdateChapter = async (chapid) => {
        setChapLoader(true)

        
        const { rawContent, plainText } = editorState!=='' && getEditorContent();
        let valid = EditchapterValidation(rawContent, plainText)

        
        

        
        if (valid===true) {
           

      
            

            let updatechapterbody = {
                "title": editchaptertitle,
                "rawdescription":rawContent,
                "plaindescription": plainText
            }

      
            await axios.put(process.env.REACT_APP_BASE_URL + `Course1/updateChapters/${id}/${chapid}`, updatechapterbody)   
                .then((res) => {
                    enqueueSnackbar('Chapter Updated Successfully', { variant: 'success' });
                    setAddChapter(false)
                    setShowChapter(false)
                    setEditChapterTitle('')
                    setEditChapterdata('')
                    setEditorState('')
                    getChapters()
                })
                .catch((error) => {
                    console.log(error, "error in handleUpdateChapter")
                }).finally(()=>{
                    setChapLoader(false)
                })
        }
    }







    


//AddLesson functionality:
    const AddLessonContent = (item) => {
        setChapterId(item._id);
        setAddLesson(true)
        setShowContent(false)
        setShowChapter(false)
        setAddChapter(false)
        setEditorState('')
    }

    const AddLessonData = async () => {
        
        const { rawContent, plainText } = editorState!==''&&getEditorContent();
        let valid = lessonValidation(plainText)
       
        
        if (valid===true) {
            setChapLoader(true)
            
            let chapterbody = {
                "title": lessonTitle,
                "rawdescription": rawContent,
                "plaindescription": plainText,
                "freelesson":freelesson1===true ? true : false,
                "enableComments":enablecomment1===true ? true : false,
                "lessonlink":lessonlink1===true ? true : false,
                "onlinelink":lessonlink1===true ? onlinelink1 : ''
            }


    
            
            await axios.post(process.env.REACT_APP_BASE_URL + `Course1/AddLesson/${id}/${chapterid}`, chapterbody)
                .then((res) => {
                    
                    enqueueSnackbar('Lesson Added Successfully', { variant: 'success' });
                    getChapters()
                    setChapterId('')
                    setAddLesson(false)
                    setChapterTitle('')
                    setEditorState('')
                    setOnlineLink('')
                    setFreeLesson1(false)
                    setEnableComment1(false)
                    setLessonLink1(false)
                })
                .catch((error) => {
                    console.log(error, "error in AddLessonData")
                }).finally(()=>{
                    setChapLoader(false)
                })
        }
    }


//Delete Chapter functionality:
    const [chapid, setChapId] = React.useState('')
    const deleteLesson = (data, item) => {
        setChapId(item._id)
        setDelLessonId(data._id)
        setDeleteLessonOpen(true)
    }

    const DeleteLessonInfo = (data, item) => {
        
        axios.put(process.env.REACT_APP_BASE_URL + `Course1/deleteLesson/${id}/${item}/${data}`)
            .then((res) => {
                enqueueSnackbar('Lesson Deleted Successfully', { variant: 'success' });
                getChapters()
                setDelLessonId('')
                setShowContent(false)
                setLessonTitle('')
                setEditLessontitle('')
                setChapId('')
                setEditorState('')
                setDeleteLessonOpen(false)

               
            })
            .catch((err) => {
                // setDelLessonId('')
                // setChapId('')
                setDeleteLessonOpen(false)
                console.log(err, "error in DeleteChapterInfo");
            })
            .finally(()=>{
                setChapLoader(true)
            })
    }


    const handleDeleteLessonClose = () => {
        setDeleteLessonOpen(false)
        setDelChapterId('')
    }


   

//Update lesson functionality:
    const UpdateLesson = async(item,data) => {
       setChapLoader(true)
       const { rawContent, plainText } = editorState!==''&&getEditorContent();
       let valid=editLessonValidation(plainText)
       if(!valid){
        setChapLoader(false);
        return
       }
      
       if(valid){
       
        let chapterbody = {
            title: editlessontitle,
            "rawdescription": rawContent,
            "plaindescription": plainText,
            "freelesson":freelesson===true ? true : false,
            "enableComments":enablecomment===true ? true : false,
            "lessonlink":lessonlink===true ? true : false,
            "onlinelink":lessonlink===true ? onlinelink : ''
        }

        
        await  axios.post(process.env.REACT_APP_BASE_URL + `Course1/updateLessons/${id}/${data._id}/${item._id}`,chapterbody)
        .then((res)=>{
            enqueueSnackbar('Lesson Updated Successfully', { variant: 'success' });
            getChapters()
            setShowContent(false)
            setChapterData('')
            setLessonData('')
            setOnlineLink('')
            setFreeLesson('')
            setEnableComment('')
            setLessonLink('')
        })
        .catch((err)=>{
            console.log(err,"error in UpdateLesson");
        }).finally(()=>{
            setChapLoader(false)
        })
       }
        
    }



  

    return (
        <div className='curriculumPage'>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={3} >
                    <Box className="chapterlist">
                        {chapters && chapters?.map((item, index) => {
                            return (
                                <Accordion key={index} className='mb-20' expanded={open[index]} onChange={handleChange(index)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${item.id}-content`}
                                        id={`panel${item.id}-header`}
                                    >
                                        <Typography className='fz-16 text_black semibold'>
                                            {/* {index >= 0 ? 'Chapter ' + ': ' + item.title : item.title} */}
                                            {index >= 0 ? `Chapter ${index + 1}: ${item.title}` : item.title}

                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List className='mb-10'>
                                            {item.lessons?.length > 0 && item.lessons?.map((data, ind) => {
                                                return (
                                                    <ListItem key={data._id} className={`eachcard mb-10 ${activeItemId === data._id ? 'active' : ''}`}
                                                        onClick={() => handleItemClick(data,item)}
                                                    >
                                                        <ListItemText primary={data.title || 'No Title'} style={{ color: 'black' }} />

                                                    
                                                        <Button disableRipple className='btnTransparent p-0 mr-10 ml-10'>
                                                            <i class="fa-solid fa-thumbs-up text_primary fz-16"></i>
                                                            <Typography className='fz-10 text_primary pb-12' component='span'>
                                                                {data.likeCount}
                                                            </Typography>
                                                        </Button>
                                                        <Button onClick={()=>handleView(data)} disableRipple className='btnTransparent p-0'><i class="fa-regular fa-comments text_primary"></i></Button>
                                                        <Box style={{ marginLeft: 20 }}>
                                                            <Button onClick={() => deleteLesson(data, item)} disableRipple className='btnTransparent p-0'><i class="fa-regular fa-solid fa-trash text_primary"></i></Button>
                                                        </Box>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                        <Box className="flex-between">
                                            <Button className='theme-btn' onClick={() => AddLessonContent(item)}><Add /> Add lesson</Button>
                                            <Button className='fz-14 text_black semibold capitalize' onClick={() => handleViewChapter(item)}>View</Button>
                                            <Button className='fz-14 text_black semibold capitalize' onClick={() => handleEditChapter(item)}>Edit</Button>
                                            <Button className='fz-14 text_black semibold capitalize' onClick={() => deleteChapter(item)}>Delete</Button>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                        {/* <Box className="addChapter_btn">
                            <Button className='theme-btn' onClick={() => {
                                [setShowContent(false), addchapter(), setAddLesson(false)]
                                }}><Add /> Add Chapter</Button>
                        </Box> */}
                        <Box className="addChapter_btn">
                            <Button className='theme-btn' onClick={() => AddchapterInfo()}><Add /> Add Chapter</Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={9} >
                    {addChapter || addLesson || showContent || showChapter ?
                        <Box>
                            {addChapter &&
                                <Box>
                                    <Box className="mb-10">
                                        <Typography component='span' variant='' className='fz-18 text_black semibold'>Chapter : </Typography>
                                        {/* <Typography component='span' variant='' className='fz-18 text_black '>Chapter Overview</Typography> */}
                                    </Box>
                                    <Card className='mt-20 p-20'>
                                        <FormControl fullWidth variant="standard" className='mb-20'>
                                            <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                name='chaptertitle'
                                                value={chaptertitle}
                                                onChange={(e) => {
                                                    setChapterTitle(e.target.value);
                                                    setError({ chaptermsg: '' })
                                                }}
                                            />
                                            {error.chaptermsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.chaptermsg}</p>}
                                        </FormControl>
                                        <Typography component='div' className='label'>
                                            Description <Typography component='span' className='star'>*</Typography>
                                        </Typography>
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                            editorStyle={{ minHeight: 500 }}
                                        />
                                        {error.editormsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.editormsg}</p>}

                                    </Card>
                                    {/* <Card className='mt-20 p-20'>
                                        <Typography className='text_black semibold'>Chapter Settings</Typography>
                                        <List sx={{ width: '100%' }}>
                                            {settings.map((value) => {
                                                const labelId = `checkbox-list-label-${value}`;

                                                return (
                                                    <ListItem
                                                        key={value}
                                                        disablePadding
                                                    >
                                                        <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={checked.includes(value)}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={labelId} className='text_black fz-14'>{value.title}</ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                        <Button className='cancel_btn'>Delete Chapter</Button>
                                    </Card> */}
                                    <Box className="text-right">
                                        <Button variant='outline' className='theme-btn mt-20 mr-10' onClick={() => cancleChapter()}>Cancel</Button>
                                       {chapLoader?
                                      <Button variant='outline' className='theme-btn mt-20 '  >Please wait...</Button>
                                      :
                                      <Button variant='outline' className='theme-btn mt-20 ' onClick={() => AddChapterData()} >Save Chapter</Button>   
                                    }
                                    </Box>
                                </Box>
                            }

                            {addLesson &&
                                <Box>
                                    <Box className="mb-10">
                                        <Typography component='span' variant='' className='fz-18 text_black semibold'>Lesson: </Typography>
                                        
                                    </Box>
                                    <Card className='mt-20 p-20'>
                                        <FormControl fullWidth variant="standard" className='mb-20'>
                                            <Typography component='div' className='label'>Title  <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                name='username'
                                                onChange={(e) => {
                                                    setLessonTitle(e.target.value);
                                                    setError({ lessonmsg: '' })
                                                }}
                                            />
                                        {error.lessonmsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.lessonmsg}</p>}
                                        </FormControl>

                                        {/* 
                                        <Editor
                                            apiKey='liboacds13xc1kciv82o8l3pt6ycawhis9pleucifckfd3na'
                                            onInit={(_evt, editor) => editorRef.current = editor}
                                            initialValue="<p>This is the initial content of the editor.</p>"
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                ],
                                                toolbar: 'undo redo | blocks | ' +
                                                    'bold italic forecolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help |  link image | media',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                audio_template_callback: (data) =>
                                                    '<audio controls>\n' +
                                                    `<source src="${data.source}"${data.sourcemime ? ` type="${data.sourcemime}"` : ''} />\n` +
                                                    (data.altsource ? `<source src="${data.altsource}"${data.altsourcemime ? ` type="${data.altsourcemime}"` : ''} />\n` : '') +
                                                    '</audio>',
                                                file_picker_callback: (callback, value, meta) => {
                                                    // Provide file and text for the link dialog
                                                    if (meta.filetype==='file') {
                                                        callback('mypage.html', { text: 'My text' });
                                                    }

                                                    // Provide image and alt text for the image dialog
                                                    if (meta.filetype==='image') {
                                                        callback('myimage.jpg', { alt: 'My alt text' });
                                                    }

                                                    // Provide alternative source and posted for the media dialog
                                                    if (meta.filetype==='media') {
                                                        callback('movie.mp4', { source2: 'alt.ogg', poster: 'image.jpg' });
                                                    }
                                                }
                                            }}
                                        /> */}
                                        <Typography component='div' className='label'>
                                            Description <Typography component='span' className='star'>*</Typography>
                                        </Typography>
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                            editorStyle={{ minHeight: 500 }}
                                        />
                                        {error.editormsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.editormsg}</p>}
                                    </Card>
                                    <Card className='mt-20 p-20'>
                                        <Typography className='text_black semibold'>Lesson Settings</Typography>
                                        {/* <List sx={{ width: '100%' }}>
                                            <ListItem disablePadding>
                                                <ListItemButton role={undefined}  dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            value={freelesson1}
                                                            onChange={()=>setFreeLesson1(true)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': `checkbox-list-label-${'make this lesson as free'}` }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={`checkbox-list-label-${'make this lesson as free'}`}>
                                                        <Typography className='text_black fw-500 fz-16'>Make this lesson as free</Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        </List> */}

                                        <List sx={{ width: '100%' }}>
                                            <ListItem disablePadding>
                                                <ListItemButton role={undefined}  dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            value={enablecomment1}
                                                            onChange={()=>setEnableComment1(true)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': `checkbox-list-label-${'Enable comments to this chapter'}` }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={`checkbox-list-label-${'Enable comments to this chapter'}`}>
                                                        <Typography className='text_black fw-500 fz-16'>Enable comments to this chapter</Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        </List>

                                        <List sx={{ width: '100%' }}>
                                            <ListItem disablePadding>
                                                <ListItemButton role={undefined}  dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            value={lessonlink1}
                                                            onChange={()=>setLessonLink1(true)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': `checkbox-list-label-${'Add online class link'}` }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={`checkbox-list-label-${'Add online class link'}`}>
                                                        <Typography className='text_black fw-500 fz-16'>Add online class link</Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                        {lessonlink1===true &&
                                            <FormControl variant="standard" fullWidth className='mb-20'>
                                                <Typography component='div' className='label'>Add Link</Typography>
                                                <TextField
                                                    className='textfield'
                                                    id="filled-size-small"
                                                    size='small'
                                                    variant="outlined"
                                                    value={onlinelink1}
                                                    onChange={(e)=>setOnlineLink1(e.target.value)}
                                                    name="experience_required"
                                                />
                                            </FormControl>
                                        }
                                        {error.newmsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.newmsg}</p>}
                                        {/* <Button className='cancel_btn'>Delete Chapter</Button> */}
                                    </Card>
                                    <Box className="text-right">
                                        {
                                            chapLoader?
                                            <Button variant='outline' className='theme-btn mt-20' >Please wait...</Button>
                                            :
                                            <Button variant='outline' className='theme-btn mt-20' onClick={() => AddLessonData()} >Save Lesson</Button>

                                        }
                                       
                                    </Box>
                                </Box>
                            }

                            {showContent &&
                                <Box>
                                    <Box className="mb-10">
                                        <Typography component='span' variant='' className='fz-18 text_black semibold'>Lesson: </Typography>
                                        <Typography component='span' variant='' className='fz-18 text_black '>{chapterdata?.title}</Typography>
                                    </Box>
                                    <Card className='mt-20 p-20'>
                                        <FormControl fullWidth variant="standard" className='mb-20'>
                                            <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                value={editlessontitle}
                                                onChange={(e)=>{
                                                    setEditLessontitle(e.target.value);
                                                    setError({ lessonmsg: '' })
                                                }}
                                                className='textfield'
                                                name='title'
                                            />
                                        </FormControl>
                                        {error.lessonmsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.lessonmsg}</p>}

                                        <Typography component='div' className='label'>
                                            Description <Typography component='span' className='star'>*</Typography>
                                        </Typography>

                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                            editorStyle={{ minHeight: 500 }}
                                        />
                                        {error.editormsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.editormsg}</p>}

                                    </Card>
                                    <Card className='mt-20 p-20'>
                                        <Typography className='text_black semibold'>Lesson Settings</Typography>
                                        {/* <List sx={{ width: '100%' }}>
                                            {settings.map((value, i) => {

                                                const labelId = `checkbox-list-label-${value}`;
                                                return (
                                                    <ListItem
                                                        key={i}
                                                        disablePadding
                                                    >
                                                        <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={checked.includes(value)}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={labelId} >
                                                                <Typography className='text_black fw-500 fz-16'>{value.title}</Typography>
                                                            </ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List> */}
                                        {/* <List sx={{ width: '100%' }}>
                                            <ListItem disablePadding>
                                                <ListItemButton role={undefined}  dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            value={freelesson}
                                                            checked={freelesson===true ? true : false}
                                                            onChange={()=>setFreeLesson(prev => !prev)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': `checkbox-list-label-${'make this lesson as free'}` }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={`checkbox-list-label-${'make this lesson as free'}`}>
                                                        <Typography className='text_black fw-500 fz-16'>Make this lesson as free</Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        </List> */}

                                        <List sx={{ width: '100%' }}>
                                            <ListItem disablePadding>
                                                <ListItemButton role={undefined}  dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            value={enablecomment}
                                                            checked={enablecomment===true ? true : false}
                                                            onChange={()=>setEnableComment(prev => !prev)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': `checkbox-list-label-${'Enable comments to this chapter'}` }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={`checkbox-list-label-${'Enable comments to this chapter'}`}>
                                                        <Typography className='text_black fw-500 fz-16'>Enable comments to this chapter</Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        </List>

                                        <List sx={{ width: '100%' }}>
                                            <ListItem disablePadding>
                                                <ListItemButton role={undefined}  dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={lessonlink===true ? true : false}
                                                            value={lessonlink}
                                                            onChange={()=>setLessonLink(prev => !prev)}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': `checkbox-list-label-${'Add online class link'}` }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={`checkbox-list-label-${'Add online class link'}`}>
                                                        <Typography className='text_black fw-500 fz-16'>Add online class link</Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                        {lessonlink===true ?
                                            <FormControl variant="standard" fullWidth className='mb-20'>
                                                <Typography component='div' className='label'>Add Link</Typography>
                                                <TextField
                                                    className='textfield'
                                                    id="filled-size-small"
                                                    size='small'
                                                    variant="outlined"
                                                    value={onlinelink}
                                                    onChange={(e)=>{
                                                        setOnlineLink(e.target.value)
                                                        setError({newmsg:""})
                                                        
                                                    }}
                                                    name="experience_required"
                                                />
                                            </FormControl>
                                        : null}
                                        {error.newmsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.newmsg}</p>}

                                        
                                        {/* <Button className='cancel_btn'>Delete Lesson</Button> */}
                                    </Card>
                                    <Box className="text-right">
                                        {/* <Button variant='outline' className='theme-btn mt-20' onClick={() => setShowContent(false)}>Update Lesson</Button> */}
                                        <Button variant='outline' className='theme-btn mt-20' onClick={() => UpdateLesson(chapterdata,lessondata)} disabled={chapLoader}>Update Lesson</Button>

                                    </Box>
                                </Box>
                            }

                            {showChapter &&
                                <Box>
                                    <Box className="mb-10">
                                        <Typography component='span' variant='' className='fz-18 text_black semibold'>Chapter: </Typography>
                                        <Typography component='span' variant='' className='fz-18 text_black '>{editchaptertitle}</Typography>
                                    </Box>
                                    <Card className='mt-20 p-20'>
                                        <FormControl fullWidth variant="standard" className='mb-20'>
                                            <Typography component='div' className='label'>Title</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                value={editchaptertitle}
                                                onChange={(e)=>{setEditChapterTitle(e.target.value);setError({editchaptermsg:''})}}
                                                className='textfield'
                                                name='title'
                                                disabled = {view === true  ? true: false}
                                            />
                                            {error.editchaptermsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.editchaptermsg}</p>}
                                        </FormControl>
                                        
                                        <Typography component='div' className='label'>
                                            Description <Typography component='span' className='star'>*</Typography>
                                        </Typography>
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={onEditorStateChange}
                                            editorStyle={{ minHeight: 500 }}
                                            readOnly = {view}
                                        />
                                         {error.editormsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.editormsg}</p>}
                                    </Card>
                                    <Box className="text-right">
                                        {view? <Button variant='outline' className='theme-btn mt-20' onClick={()=>handleCloseChapterView(editchapterdata._id)}>Close</Button>:
                                        <Button variant='outline' className='theme-btn mt-20' disabled={chapLoader} onClick={()=>handleUpdateChapter(editchapterdata._id)} >Update Chapter</Button>
                                        }
                                        
                                    </Box>
                                </Box>
                            }

                       
                        </Box>

                        :
                        <Card className='h-100p flex-col flex-center justify-content-center'>
                            <Typography component='h5' variant='h5' className=' text_black semibold'>Add Content to your Course</Typography>
                            <Typography component='div' variant='body1' className=' text_black '>Create New or Update Existing Lessons.</Typography>

                        </Card>
                    }
                </Grid>
            </Grid>
            <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className='custom_modal'
                maxWidth='md'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Comments
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 15,

                        }}
                    >
                        <CloseIcon className='fz-18' />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Comment_section lessonId={lessId} />
                </DialogContent>
                <DialogActions>
                    <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>

            {/* To delete Chapter */}
            <Dialog
                open={deleteopen}
                keepMounted
                onClose={handleDeleteClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'
            >
                <DialogTitle>{"Would you like to delete this chapter?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteClose} className='cancel_btn'>No</Button>
                    <Button onClick={() => DeleteChapterInfo(delchapterid)} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>


            {/* To delete Lesson */}
            <Dialog
                open={deletelessonopen}
                keepMounted
                onClose={handleDeleteLessonClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'
            >
                <DialogTitle>{"Would you like to delete this lesson?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteLessonClose} className='cancel_btn'>No</Button>
                    <Button onClick={() => DeleteLessonInfo(dellessonid, chapid)} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
