import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Tooltip, Avatar, Grid,Checkbox, TextField, RadioGroup, TextareaAutosize, FormControlLabel, Typography, IconButton,Input, Radio} from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { AddAPhoto} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Avatarimage from "../../../assets/images/Avatar.png"
import Spinner from '../../../components/spinner'
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoComplete_Edit from "./Autocomplete_edit";
import { AiOutlineInfoCircle } from 'react-icons/ai';



export default function EditTeacher() {
    let location = useLocation()
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }

  

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 15,
                            color: (theme) => theme.palette.grey[500],
                            border: '1px solid #E5EBF0',
                            borderRadius: '8px',
                            height: 38,
                            width: 38
                        }}
                    >
                        <CloseIcon className='fz-18' />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#f3714d',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });

    function BpRadio(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }

    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    var editId = location.state.data._id
    const [user, setUser] = React.useState(
        {
            firstname: location.state.data.name ? location.state.data.name : "",
            uniquename: location.state.data.username, image: location.state.data.image,
            Imageprew: "",
            Imageraw: "",
            email: location.state.data.email, specialization:
                location.state.data.specialization,
                 gender: location.state.data.gender,
            calPasskey:location.state.data.calPasskey?location.state.data.calPasskey:'',
            phone: location.state.data.contact_number,
            description: location.state.data.description,
            instagram: location.state.data.instagram?location.state.data.instagram:'',
            facebook: location.state.data.facebook?location.state.data.facebook:'',
            twitter: location.state.data.twitter?location.state.data.twitter:"",
            youtube: location.state.data.youtube ? location.state.data.youtube:""
            , pinterest: location.state.data.pinterest?location.state.data.pinterest:'',
            tiktok: location.state.data.tiktok ? location.state.data.tiktok : ''
        });

  
     
    const [error, setError] = React.useState({ firstname: "", email: "", image: "",phone:" " })
    const [statesingle, setStatesingle] = React.useState(location.state.data.image)
    const [statesingle2, setStatesingle2] = React.useState(location.state.data.image)
    const [isToggled, setToggled] = React.useState(location.state.data.SetVerifyTeacher)
    const [isPrimary, setPrimary] = React.useState(location.state.data.senior_teacher)
    const [ setPlans] = React.useState([])
    const [lat, setLat] = React.useState(location.state.data?.location_coord?.coordinates[0])
    const [lng, setLng] = React.useState(location.state.data.location_coord?.coordinates[1])
    const [selectedPlan, setSelectedPlan] = React.useState(location.state.data.planId ? location.state.data.planId.name : "")
    const [isChecked, setChecked] = React.useState(location.state.data.merit)
    const [cityName, setCityName] = React.useState(location.state.data.address)
    const [truebutton, setTruebutton] = React.useState(false)
    const [subscribed] = React.useState(location.state.data.isSubscribed)
    const [isApproveChecked, setIsApproveChecked] = React.useState(true)
    const [isLegend, setIsLegend] = React.useState(location.state.data.isLegend)
    const [availForMeeting, setAvailForMeeting] = React.useState(location.state.data?.AvailForMeeting ? location.state.data?.AvailForMeeting : false )

console.log(availForMeeting)


    let username, uservalue;
    const handleChange = (e, index) => {
        username = e.target.name;
        uservalue = e.target.value;
        const updatedSpecializations = [...user.specialization];
        updatedSpecializations[index] = uservalue;
        if (username === 'username') {
            uservalue = uservalue.trim();
        }
        setUser({ ...user, [username]: uservalue, specialization: updatedSpecializations });
        setError('')
        
    };

    const handleAddSpecialization = () => {
        setUser({ ...user, specialization: [...user.specialization, ''] });
    };

    const handleRemoveSpecialization = (index) => {
        const updatedSpecializations = [...user.specialization];
        updatedSpecializations.splice(index, 1);
        setUser({ ...user, specialization: updatedSpecializations });
    };



  



    const handleprofilephoto = (e) => {
        const file = e.target.files[0];
        if (file.size > 1024 * 1024) {
            setError({ image: 'Image should be less than 1 MB' });
            return;
        } else {
            setError({ image: '' });
        }
        setStatesingle(URL.createObjectURL(file));
        setStatesingle2(file);
    }

    const Validator = () => {
        let isValid = true;
        const errors = {};
        if (!user.firstname) {
            errors.firstname = "This field is required";
            isValid = false;
        }
        if (!user.phone) {
            errors.phone = "This field is required";
            isValid = false;
        }
        if(availForMeeting){
            if(!user.calPasskey){
                errors.calPasskey = "This field is required";
                isValid = false;
            }
        }
        setError(errors);
        return isValid;
    };

    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }

    const handleChecked = () => {
        setChecked(!isChecked)
    }


    const handleToggle = async (e) => {

        if (!subscribed) {
            enqueueSnackbar("Please complete the payment process for approving", { variant: 'error' });
            };
        

        const body = {
            SetVerifyTeacher: e.target.checked,
        };

        await axios.put(process.env.REACT_APP_BASE_URL + `Admin/UpdateSetVerifyTeacher/${editId}`, body, tokendata).then((res) => {
            setToggled(!isToggled)
            
        }).catch((err) => {
            console.error("Error while toggling teacher:", err);
        })
    }

    const handleDisabledToggleClick = async (e) => {
        enqueueSnackbar("Please complete the payment process for approving", { variant: 'error' });
    };

    const handleApproveChecked = () => {
        setIsApproveChecked(!isApproveChecked)
    }

    const handleIsLegend = () => {
        setIsLegend(!isLegend)
    }
    const handleIsAvailForMeeting = () => {
        setAvailForMeeting(!availForMeeting)
    }

    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

    const handleUpdate = async (details) => {
        var Valid = Validator()
        if (Valid) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("image", statesingle2 ? statesingle2 : "");
            formData.append("name", user.firstname);
            formData.append("email", user.email);
            formData.append("SetVerifyTeacher", isToggled);
            formData.append("username", user.uniquename);
            // formData.append("specialization", user.specialization);
            const transformedSpecializations = user.specialization.flatMap(spec => spec.split(',').map(s => s.trim())).filter(Boolean);
            if (transformedSpecializations.length === 0) {
                formData.append(`specialization[]`, "");
            }
            else {
                transformedSpecializations.forEach((spec, index) => {
                    formData.append(`specialization[${index}]`, spec);
                });
            }
            formData.append("description", user.description ? user.description : "");
            

            formData.append("address", cityName ? cityName : "");
            formData.append("latitude", lat);
            formData.append("longitude", lng);
            formData.append("contact_number", user.phone);
            formData.append("gender", user.gender ? user.gender : '');
            formData.append("merit", isChecked)
            formData.append("senior_teacher", isPrimary)
            formData.append("instagram", user.instagram)
            formData.append("facebook", user.facebook)
            formData.append("twitter", user.twitter)
            formData.append("youtube", user.youtube)
            formData.append("pinterest", user.pinterest)
            formData.append("tiktok", user.tiktok)
            formData.append("bundleApproved", isApproveChecked)
            formData.append("isLegend", isLegend)
            formData.append("AvailForMeeting", availForMeeting)
            formData.append("calPasskey", availForMeeting ? user.calPasskey : "" )
            formData.append("planId", selectedPlan)
            await axios.put(process.env.REACT_APP_BASE_URL + `Admin/UpdateTeacher/${editId}`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false)
                    if (details === 'addWithNavigate') {
                        navigate(`/teachers/details/events/${`Teacher`}/${res.data.updateTeacher._id}`);
                    } else if (details === 'add') {
                        enqueueSnackbar("Teacher Updated successfully", { variant: 'success' });
                        navigate('/teachers')
                    }
                }).catch((error) => {
                    setTruebutton(false)
                    console.log(error.response, "error");
                    // enqueueSnackbar(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Something went wrong.", { variant: 'error' })
                })
        }
    }

    React.useEffect(() => {
        getPlans()
    }, [])

    const getPlans = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Subscription/getSubscriptionPlansYogaTeacher`, tokendata).then((res) => {
            setPlans(res.data.getSubscriptiondata)
        }).catch((error) => {
            console.log(error, "Error ");
        })
    }

    return (
        <Box className=''>
            <MiniDrawer menuOrder={2} submenuOrder={5} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className='whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Edit Teacher</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <Box className='personal-info-tab'>
                                            <Typography component='div' className='label' >
                                                <center>Profile Photo<Typography component='span' className='star'></Typography></center>
                                            </Typography>
                                            <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleprofilephoto} />
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        {statesingle && statesingle.slice(0, 4) === "blob" ? <Avatar src={statesingle}>
                                                        </Avatar> : <React.Suspense fallback={<Spinner />}>
                                                            <Avatar src={statesingle ? process.env.REACT_APP_BASE_URL + statesingle : Avatarimage}> </Avatar>
                                                        </React.Suspense>
                                                        }
                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed .jpeg, .jpg, *.png<br />max size of 1 MB
                                            </Typography>
                                            {/* {error.image && <p style={{ color: 'red' }} className="fz-13 ml-20 text-center" >{error.image}</p>} */}
                                        </Box>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={12}>
                                        <FormControl fullWidth>
                                            <Typography component='div' className='label'>
                                                Subscription <Typography component='span' className='star'>*</Typography>
                                            </Typography>
                                            <TextField
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                placeholder={selectedPlan}
                                                value={selectedPlan ? selectedPlan : "Not Subscribed"}
                                                className="select"
                                                onChange={(e) => setSelectedPlan(e.target.value)}
                                                disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='Name'
                                                value={user.firstname}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Username: <Typography component='span' className='star'>*</Typography>
                                                <Tooltip title="This name will be used to create a unique URL for teacher">
                                                    <IconButton aria-label="information">
                                                        <AiOutlineInfoCircle size={20} />
                                                    </IconButton>
                                                </Tooltip></Typography>                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='username'
                                                className='textfield'
                                                placeholder='User Name'
                                                value={user.uniquename}
                                                disabled
                                            // onChange={handleChange}

                                            />
                                        </FormControl>
                                        {/* <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span> */}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={user.email}
                                                placeholder='Email'
                                                onChange={handleChange}
                                                disabled

                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid>

                                   {subscribed === true ? <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className=''>
                                            <Typography component='div' variant='body1' className='fz-14 label mb-0 mr-10'>Teacher Approved? </Typography>
                                            <FormControlLabel className='m-0' control={<Switch size='small' onClick={(e) => handleToggle(e)} checked={isToggled} sx={{ m: 1 }} />} />
                                        </Box>
                                    </Grid> : subscribed === false &&
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box className=''>
                                        <Typography component='div' variant='body1' className='fz-14 label mb-0 mr-10'>Teacher Approved? </Typography>
                                        <FormControlLabel className='m-0' control={<Switch size='small' onClick={(e) => handleDisabledToggleClick(e)} checked={isToggled} sx={{ m: 1 }} />} />
                                    </Box>
                                </Grid>}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={isPrimary}
                                                    onChange={handleTogglePrimary}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Senior Teacher? </Typography>}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Specialization: <Typography component='span' ></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='specialization'
                                                className='textfield'
                                                placeholder='Specialization'
                                                value={user.specialization}
                                                onChange={handleChange}

                                            />
                                        </FormControl>
                                    </Grid> */}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>
                                                Specialization:
                                            </Typography>
                                            {user.specialization.map((value, index) => (
                                                <Grid container spacing={1} alignItems="center" key={index}>
                                                    <Grid item xs={index > 0 ? 10 : 12}> {/* Adjust width for cancel icon */}
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            className='textfield'
                                                            placeholder={`Specialization`}
                                                            value={value}
                                                            onChange={(e) => handleChange(e, index)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {index > 0 && (
                                                        <Grid item xs={2}> {/* Adjust width for cancel icon */}
                                                            <IconButton onClick={() => handleRemoveSpecialization(index)}>
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            ))}
                                            <IconButton onClick={handleAddSpecialization}>
                                                <AddIcon />
                                            </IconButton>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={handleChecked}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Would you like to add this teacher to the Teachers of Great Merit?</Typography>}
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={isApproveChecked}
                                                    onChange={handleApproveChecked}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Would you like to make this teacher to add bundle offer?</Typography>}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={isLegend}
                                                    onChange={handleIsLegend}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Would you like to Make this teacher as Yoga legends?</Typography>}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={availForMeeting}
                                                    onChange={handleIsAvailForMeeting}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Would you like to Make this teacher Avail For Meeting?</Typography>}
                                        />
                                    </Grid>

                                    {
                                        availForMeeting && <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Cal Pass Key<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='calPasskey'
                                                className='textfield'
                                                placeholder='PassKey'
                                                value={user.calPasskey}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.calPasskey}</span>
                                    </Grid>
                                    }

                                    <Grid item lg={12} className='radiobtnGrid'>
                                        <Typography component='div' className='label '>Gender:</Typography>
                                    </Grid>
                                    <Grid item lg={12} className='pt-0'>
                                        <Box className=" ">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-customized-radios"
                                                name="gender"
                                                onChange={handleChange}
                                                value={user.gender}
                                            >
                                                <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:<Typography component='span' className='star'> *</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                className='textfield'
                                                value={user.phone}
                                                placeholder='Phone'
                                                onChange={handleChange}
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 10 }}

                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.phone}</span>
                                    </Grid>
                                
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Address:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Address"
                                                className='textfield textarea'
                                                name='address'
                                                value={user.address}
                                                onChange={handleChange}
                                                minRows={2}
                                            />
                                        </FormControl>
                                    </Grid> */}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Location</Typography>
                                            <AutoComplete_Edit cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} />
                                            {error.location && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.location}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Details"
                                                className='textfield textarea'
                                                name='description'
                                                value={user.description}
                                                onChange={handleChange}
                                                minRows={3}
                                                style={{ resize: 'none' }} />
                                        </FormControl>
                                    </Grid>

                                    {/* Edit Social Media links */}
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Instagram Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='instagram'
                                                className='textfield'
                                                value={user.instagram}
                                                placeholder='Instagram Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Facebook Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='facebook'
                                                className='textfield'
                                                value={user.facebook}
                                                placeholder='Facebook Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Twitter Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='twitter'
                                                className='textfield'
                                                value={user.twitter}
                                                placeholder='Twitter Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Youtube Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='youtube'
                                                className='textfield'
                                                value={user.youtube}
                                                placeholder='Youtube Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Pinterest Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='pinterest'
                                                className='textfield'
                                                value={user.pinterest}
                                                placeholder='Pinterest Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Tiktok Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='tiktok'
                                                className='textfield'
                                                value={user.tiktok}
                                                placeholder='Tiktok Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10' onClick={() => handleUpdate('add')}>Update</Button>
                                        <Button variant="contained" className='addbtn mr-10' onClick={() => handleUpdate('addWithNavigate')}>Save + Continue</Button>
                                    </Box>
                                    : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10'>Please Wait</Button>
                                    </Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}
