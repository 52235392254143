import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MiniDrawer from '../../../../../components/drawer'
import DataTable from './Table'
import Sidebar from '../../Sidebar'
export default function Video() {
    let navigate = useNavigate()
    const params = useParams()
    const auth = JSON.parse(localStorage.getItem("userData"))

    const addVideo = () => {
        navigate(`/teachers/details/video/edit/${params.role}/${params.id}`);
    }


    return (
        <Box className={auth.role_id == 1 ? 'withSubmenu' : ''}>
        {auth.role_id == 1 ? <MiniDrawer menuOrder={2} submenuOrder={params.role === "Legend" ? 6 : 5} />
            : <MiniDrawer menuOrder={7} />}
        {auth.role_id == 1 ? <Sidebar subtype={4} /> : ''}
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Videos</Typography>
                    <Button variant="contained" className='addbtn' onClick={() => addVideo()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Video</Button>
                </Box>
                <Box className='whiteContainer '>
                    <DataTable />
                </Box>
            </Box>
        </Box>
    )
}