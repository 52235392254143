import React, {  useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import Check from '@mui/icons-material/Check';

import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios'
import { useNavigate,useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, TextField, Checkbox, Typography,  FormControlLabel} from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Close} from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Sidebar from './../../Sidebar'








const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#784af4',
	}),
	'& .QontoStepIcon-completedIcon': {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
	'& .QontoStepIcon-circle': {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;
	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};


export default function Service_Steppyform() {
	const navigate = useNavigate()
	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}
    const params = useParams()
	const { enqueueSnackbar } = useSnackbar()
	const [bookreviews, setBookreviews] = React.useState({ booktitle: '', description: "", publishby: "", publisher: "", author: "", pagecount: "", photographedby: "", isbn: "" , reviewed_by: ""})
	const [truebutton, setTruebutton] = React.useState(false)
	const [error, setError] = React.useState({ booktitle: "", video: "", location: "", publishby: "", publisher: "" })
	const [categoryId, setCategoryId] = React.useState('')
	const [subcategoryId, setSubCategoryId] = React.useState('')
	const [catError, setCatError] = React.useState('')
	const [subcatError, setSubCatError] = React.useState('')
	const [imageError, setImageError] = React.useState('')
	const [state2, setState2] = React.useState([])
	const [statesingle, setStatesingle] = React.useState([])
	const [statesingle2, setStatesingle2] = React.useState([])
	const [description, setDescription] = React.useState('')


	const [category, setCategory] = React.useState([])
	const [subcat, setSubcat] = React.useState([])
	// const [date, setDate] = React.useState(dayjs());
	const [values, setValues] = React.useState(dayjs());
	const [review_posted_online, setReview_posted_online] = React.useState(dayjs())
	const [isPrimary, setPrimary] = React.useState(false)
	const [isPublic, setPublic] = React.useState(false)
	const [selectGallert] = useState('Image')
	// const [isToggled, setToggled] = React.useState(false)
	const auth = JSON.parse(localStorage.getItem('userData'))

	






	// React.useEffect(() => {
	// 	if (dataofeditor) {
	// 		try {
	// 			const contentState = stateFromHTML(dataofeditor);
	// 			setEditorState(EditorState.createWithContent(contentState));
	// 		} catch (error) {
	// 			console.error("Error during HTML to ContentState conversion:", error);
	// 		}
	// 	}
	// }, [dataofeditor]);



	React.useEffect(() => {
		getCategory()
	}, [])

	React.useEffect(() => {
		getSubCategory()
	}, [categoryId])


	const getCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
			setCategory(res.data.category)
		}).catch((error) => {
			console.log(error.response, "error");
		})
	}
	const getSubCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
			.then((res) => { setSubcat(res.data.subcategory) }).catch((error) => {
				console.log(error.response, "error");
			})
	}

	const handleRemoveRestart = () => {
		setSubCategoryId('')
	}

	


	let name, value;
	const handlechange = (e) => {
		name = e.target.name;
		value = e.target.value;
		setBookreviews({ ...bookreviews, [name]: value })
	}

	


	const SingleImage = (e, index) => {
		setImageError('');
		const fileObj = e.target.files;
	
		const fileArray = [];
		const filepath = [];
	
		for (let i = 0; i < fileObj.length; i++) {
			let file = fileObj[i];
			// Check if file size is less than 1MB (1000000 bytes)
			if (file.size > 1000000) {
				setImageError("Image should be less than 1 MB");
				continue; // Skip adding this file to state
			}
	
			fileArray.push(URL.createObjectURL(file));
			filepath.push(file);
		}
	
	
	
		setStatesingle(prevValue => [...prevValue, ...fileArray]);
		setStatesingle2(prevValue => [...prevValue, ...filepath]);
	};

	const indexDelete1 = (e, index) => {
		var filteredpreview = statesingle.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = statesingle2.filter((val, i) => {
			return i !== index;
		});
		setStatesingle(filteredpreview);
		setStatesingle2(filteredraw);
	}



	const validate = () => {
		if (!bookreviews.booktitle && statesingle2?.length===0 && !bookreviews.publisher) {
			setError({ booktitle: "This field is required.", publisher: "This field is required." })
			setCatError("This field is required.")
			setImageError("This field is required.")
			return false
		}
		if (!bookreviews.booktitle) {
			setError({ booktitle: "This field is required." })
			return false
		}
		if (!bookreviews.publisher) {
			setError({ publisher: "This field is required." })
			return false
		}
		if (!categoryId) {
			setCatError("This field is required.")
		}
		if (statesingle2?.length===0) {
			setImageError("This field is required.")
			return false
		}

		return true
	}




	const handleTogglePrimary = () => {
		setPrimary(!isPrimary)
	}
	const handleTogglePublic = () => {
		setPublic(!isPublic)
	}

	const handleAddService = () => {
		var multipleImage = state2.filter(x => x != null);
		var valid = validate()
		// var validnew = validatenew()

		if (valid===true) {
			// const unlayer = emailEditorRef.current?.editor;
			// unlayer?.exportHtml((data) => {
			// 	const { design, html } = data;
			// 	const sanitizedHtml = DOMPurify.sanitize(html);
	
				setTruebutton(true)
				const formData = new FormData();
				formData.append("createdby[]", params && params.id)
				formData.append("Book_title", bookreviews.booktitle)
				formData.append("Date", values)
				formData.append("category_id", categoryId)
				formData.append("subcategory_id", subcategoryId)
				// formData.append("Book_publish", isToggled)
				formData.append("public", isPublic)
				formData.append("primary", isPrimary)
				formData.append("Book_published_by", bookreviews.publisher)
				formData.append("author", bookreviews.author)
				formData.append("photographed_by", bookreviews.photographedby)
				formData.append("page_count", bookreviews.pagecount)
				formData.append("Isbn", bookreviews.isbn)
				formData.append("review_posted_online", review_posted_online)
				formData.append("reviewed_by", bookreviews.reviewed_by)
				formData.append("Book_shortdescription", description)
				formData.append("gallery", selectGallert === 'Image' ? 'Image' : 'Video')
				{ statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
				{
					selectGallert === 'Image' ? <>
						{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '')}
						{/* {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')} */}
					</>
						:
						<>
							{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", '') }) : formData.append("additionalimages", '')}
							{/* {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')} */}
							</>
				}
			
				axios.post(process.env.REACT_APP_BASE_URL + `BookReview/AddBookReview`, formData, { headers: headerformdata }).then((res) => {
					setTruebutton(false)
					navigate(`/teachers/details/bookreview/${params.role}/${params.id}`);
					setBookreviews({ booktitle: '', description: "", publisher: "", author: "", photographedby: "", pagecount: "", isbn: "", reviewed_by : "" })
					setCategoryId('');
					setSubCategoryId('');
					
					setState2([])
					enqueueSnackbar("Book Review added successfully", { variant: 'success' })
					
				}).catch((err) => {
				
					enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
					console.log(err, "error");
				})
				.finally(()=>{
					setTruebutton(false)
				})
			// })
		}
	}


	// const handleVideoChange = (e, index) => {
	// 	setError(prevError => ({ ...prevError, video: '' }))
	// 	var fileObj = [];
	// 	var fileArray = [];
	// 	var filepath = [];
	// 	fileObj.push(e.target.files)
	// 	for (let i = 0; i <= fileObj[0].length; i++) {
	// 		if (i < fileObj[0].length) {
	// 			fileArray.push(URL.createObjectURL(fileObj[0][i]))
	// 			filepath.push(fileObj[0][i])
	// 		}
	// 	}
	// 	setVideoPreviewUrl(fileArray)
	// 	setSelectedVideo(filepath)
	// }




	const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };


	return (
		// <Box className='withSubmenu'>
		<Box className={auth.role_id===1 ? 'withSubmenu' : ''}>
        {auth.role_id===1 ? <MiniDrawer menuOrder={2} submenuOrder={params.role === "Legend" ? 6 : 5} />
            : <MiniDrawer menuOrder={14} />}
        {auth.role_id===1 ? <Sidebar subtype={8} /> : ''}
			<Box component="section" className="contentWraper">
				<Box className='whiteContainer p-20'>
					<Typography component='h6' className='formTitle mb-20'>Add Book Review</Typography>

						<Box className='steppy_content'>
							<Box className="maxw_700">
								<Box className="mb-20">
									<Typography component='div' className='label'>Book Title <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='Title'
											size="small"
											name='booktitle'
											className='textfield'
											style={{ width: '100%' }}
											value={bookreviews.booktitle}
											onChange={(e) => {
												handlechange(e); setError(prevError => ({ ...prevError, booktitle: '' }))
											}}
										/>
										{error.booktitle && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.booktitle}</p>}
									</FormControl>
								</Box>

								<Box className="mb-20">
									<Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl fullWidth >
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={categoryId ? categoryId : 'sel'}
											onChange={(e) => { setCategoryId(e.target.value); setCatError("") }}>
											<MenuItem disabled value={'sel'} >Select category</MenuItem>
											{category && category?.length > 0 ? category.map((data) => (
												<MenuItem value={data._id}>{data.category_title}</MenuItem>)) : <MenuItem disabled>{"No Record's found"}</MenuItem>}
										</Select>
										{catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
									</FormControl>
								</Box>
								<Box className="mb-20">
									<Typography component='div' className='label'>Select Sub Category</Typography>
									<Box  >
										<FormControl fullWidth style={{ flex: 1 }}>
											<Select
												className="select"
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												disabled={categoryId !== '' ? false : true}
												value={subcategoryId ? subcategoryId : 'sel18'}
												onChange={(e) => {
													setSubCategoryId(e.target.value);
													setSubCatError('');
													setCatError('');
													setSubCatError('');
												}}
											>
												<MenuItem disabled value={'sel18'}>Select sub category</MenuItem>
												{subcat && subcat?.length > 0 ? (
													subcat.map((data5) => (
														<MenuItem value={data5._id}>{data5.subcategory_title}</MenuItem>
													))) : (<MenuItem disabled>{"No Record's found"}</MenuItem>)}
											</Select>
											{subcatError && (<p style={{ color: 'red' }} className="fz-13 m-0">{subcatError}</p>)}
										</FormControl>
										{subcategoryId !== '' && (
											<RestartAltIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleRemoveRestart()} />
										)}
									</Box>
								</Box>

								<Box className="mb-20">
									<Typography component='div' className='label'>Published Date <Typography component='span' ></Typography></Typography>
									<FormControl variant="standard" fullWidth className="datepicker">
										<LocalizationProvider dateAdapter={AdapterDayjs} >
											<DemoContainer components={['DatePicker', 'DatePicker']} fullWidth>
												<DatePicker
													className='textfield w100'
													value={values}
													onChange={(newValue) => setValues(newValue)}
													minDate={dayjs().subtract(200, 'year')}
													format="DD/MM/YYYY"
												/>
											</DemoContainer>
										</LocalizationProvider>
									</FormControl>
								</Box>

								{/* <Grid item xs={12} sm={12} md={12} lg={12}>
									<Box className='mb-10 d-flex align-items-start justify-content-between' >
										<Box className=' text-right' style={{ display: 'flex', justifyContent: 'space-between' }}>
											<Typography component='div' variant='body1' className='fz-14 '>Book Publish: </Typography>
											<FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<IOSSwitch onClick={handleToggle} sx={{ m: 1 }} />} />
										</Box>
									</Box>
								</Grid> */}

								<Box className='mb-20'>
									<FormControlLabel className="checkbox_list"
										control={
											<Checkbox
												checked={isPublic}
												onChange={handleTogglePublic}
												name='checkbox-value'
												color='primary'
											/>
										}
										label={<Typography component='div' className='label mb-0'>Do you want to make this review as Public? </Typography>}
									/>
									<FormControlLabel className="checkbox_list"
										control={
											<Checkbox
												checked={isPrimary}
												onChange={handleTogglePrimary}
												name='checkbox-value'
												color='primary'
											/>
										}
										label={<Typography component='div' className='label mb-0'>Do you want to make this review as Primary? </Typography>}
									/>
								</Box>

								<Box className="mb-10">
									<Typography component='div' className='label'>Publisher<Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='Publisher'
											size="small"
											name='publisher'
											className='textfield'
											style={{ width: '100%' }}
											value={bookreviews.publisher}
											onChange={(e) => {
												handlechange(e); setError(prevError => ({ ...prevError, publisher: '' }))
											}}
										/>
										{error.publisher && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publisher}</p>}
									</FormControl>
								</Box>

								<Box className="mb-10">
									<Typography component='div' className='label'>Author</Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='Author'
											size="small"
											name='author'
											className='textfield'
											style={{ width: '100%' }}
											value={bookreviews.author}
											onChange={(e) => {
												handlechange(e); 
											}}
										/>
										{/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
									</FormControl>
								</Box>
								<Box className="mb-10">
									<Typography component='div' className='label'>Reviewed By</Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='Reviewed By'
											size="small"
											name='reviewed_by'
											className='textfield'
											style={{ width: '100%' }}
											value={bookreviews.reviewed_by}
											onChange={(e) => {
												handlechange(e); 
											}}
										/>
										{/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
									</FormControl>
								</Box>

								<Box className="mb-20">
									<Typography component='div' className='label'>Review Posted Online<Typography component='span' ></Typography></Typography>
									<FormControl variant="standard" fullWidth className="datepicker">
										<LocalizationProvider dateAdapter={AdapterDayjs} >
											<DemoContainer components={['DatePicker', 'DatePicker']} fullWidth>
												<DatePicker
													className='textfield w100'
													value={review_posted_online}
													onChange={(newValue) => setReview_posted_online(newValue)}
													minDate={dayjs()}
													format="DD/MM/YYYY"
												/>
											</DemoContainer>
										</LocalizationProvider>
									</FormControl>
								</Box>

								


								<Box className="mb-10">
									<Typography component='div' className='label'>Photographed By</Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='Photographed By'
											size="small"
											name='photographedby'
											className='textfield'
											style={{ width: '100%' }}
											value={bookreviews.photographedby}
											onChange={(e) => {
												handlechange(e); 
											}}
										/>
										{/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
									</FormControl>
								</Box>

								<Box className="mb-10">
									<Typography component='div' className='label'>Page Count</Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='Page Count'
											size="small"
											name='pagecount'
											onInput={(e) => onlyNumbers(e)}
											className='textfield'
											style={{ width: '100%' }}
											value={bookreviews.pagecount}
											onChange={(e) => {
												handlechange(e); 
											}}
										/>
										{/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
									</FormControl>
								</Box>

								<Box className="mb-10">
									<Typography component='div' className='label'>ISBN</Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='ISBN'
											size="small"
											name='isbn'
											className='textfield'
											style={{ width: '100%' }}
											value={bookreviews.isbn}
											onChange={(e) => {
												handlechange(e); 
											}}
										/>
										{/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
									</FormControl>
								</Box>



								<Grid item xs={12} sm={12} md={12}  >
									<Box className='flex-center flex-between mt-20'>
										<Typography component='div' className='label'>Cover Image <Typography component='span' className='star'> * </Typography></Typography>
										<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
											{statesingle.length < 1 &&
												<Button className="upload_btn" variant="contained" component="label">
													<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
													<input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
												</Button>
											}
										</Box>
									</Box>
								</Grid>

								{imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}

								<Grid item xs={12} sm={12} md={12}  >
									{statesingle.length > 0 &&
										<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
											{statesingle.length > 0 ? statesingle.map((x, i) => (
												<Grid item xs={6} sm={4} md={4} lg={4} >
													<Box className='uploaded_img' >
														<Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
														<img src={x} alt='' height={155} width={195} />
													</Box>
												</Grid>
											)) : ""}
										</Grid>
									}
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 600px for better quality picture.</Typography>
								</Grid>

								<Grid item xs={12} sm={12} md={12}  >
									<Box className="mb-10">
										<Typography component='div' className='label'>Short Description</Typography>

										<FormControl variant="standard" fullWidth>
											<textarea
												placeholder='Short Description '
												className='textarea'
												defaultValue={description}
												onChange={(e) => setDescription(e.target.value)}
												rows={4}
												cols={40} />
										</FormControl>
									</Box>
								</Grid>
								
							</Box>
						</Box >
						{truebutton?(
							<>
								<Box className='heading-btns mt-20 w100' style={{ textAlign: 'end' }}>
									{/* <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button> */}
									<Button  variant="contained" className='theme-btn'>Please wait...</Button>
								</Box>
							</>

						):(

							<>
								<Box className='heading-btns mt-20 w100' style={{ textAlign: 'end' }}>
									{/* <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button> */}
									{truebutton?
									<Button  variant="contained" className='theme-btn'>Please wait...</Button>
									:
									<Button onClick={handleAddService} variant="contained" className='theme-btn'>Submit</Button>
								}
									
								</Box>
							</>
						)}
							
				</Box >
			</Box >
		</Box >
	)
}